import React, { useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import {
  Title,
  WelcomeText,
  SignUpText,
  HorizontalLine,
  ForgotPasswordText,
} from "./Signup.style";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signup, update } from "../../../redux/auth/authState";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const Signup = ({ signup, signupSuccess, update }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    async function updateState() {
      update();
    }
    updateState();
    if (signupSuccess) {
      navigate("/signin");
    }
  }, [signupSuccess, navigate]);

  const onFinish = (values) => {
    signup(values);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100dvh",
        background: "#EAEAEA",
      }}
    >
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        layout="vertical"
        style={{
          maxWidth: 600,
          width: "100%",
          padding: "50px",
          background: "white",
          borderRadius: 10,
          marginBottom: "20px",
        }}
        scrollToFirstError
      >
        <Title>
          ETHIO<b>MAPS</b>
        </Title>
        <WelcomeText>Welcome</WelcomeText>
        <SignUpText className="sign-in-text">
          Create an account to use our services
        </SignUpText>
        <HorizontalLine />

        <Form.Item
          name="firstname"
          label="Firstname"
          rules={[
            {
              required: true,
              message: "Please input your firstname!",
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastname"
          label="Lastname"
          rules={[
            {
              required: true,
              message: "Please input your lastname!",
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "The input is not a valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
          style={{ marginBottom: 10 }}
        >
          <Input.Password />
        </Form.Item>

        <ForgotPasswordText>
          Already have an account?<Link to="/signin"> Sign In </Link>
        </ForgotPasswordText>

        <Form.Item>
          <Button
            className="w-full text-white"
            style={{
              background: "#626262",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "5px",
            }}
            htmlType="submit"
          >
            Sign Up
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    ...state.auth,
    // ...state.search,
  };
}
const mapDispatchToProps = {
  update: update,
  signup: signup,
  //   dispatchUpdate: update
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
