import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toggleCollapsed } from "../../redux/app/appState";
import { updateDirectionClicked } from "../../redux/navigation/navState";
import { BiSolidDirectionRight } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { Button, Form, Modal, Input } from "antd";
import place from "../../image/place.jpg";
import "../routes/Search/routes.css";
import { getPlace, setShowContributions } from "../../redux/places/placeState";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import MapComponent from "../Places/addPlaceMap";
import { editPlace } from "../../redux/places/placeState";
const Sidebar = ({
  collapsed,
  myPlace,
  resultLabel,
  setShowContributions,
  updateDirectionClicked,
  getPlace,
  editPlace,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState(null);

  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values, place) => {
    const token = localStorage.getItem("publicToken");
    editPlace({ values, token, place });
    // Handle form submission here
    setIsModalVisible(false);
  };

  const handleMapClick = (coordinates) => {
    setCoordinates(coordinates);
    setMarkerPosition(coordinates);
    form.setFieldsValue({
      coordinates: `${coordinates.lat}, ${coordinates.lng}`,
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("publicToken");
    getPlace({ token });
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    return date.toLocaleString("en-US", options);
  };

  const handleEdit = () => {
    setIsModalVisible(true);
  };
  return (
    <div className="flex border-l-2">
      {/* sidebar for mobile */}
      <div
        className="hidden mobile:block mobile:mt-1 w-full p-2"
        style={{ height: "calc(1.7/4 * 100dvh" }}
      >
        <img alt="user" style={{ height: "51%", width: "100%" }} src={place} />
        <p
          className=" font-medium "
          style={{ fontSize: "100%", lineHeight: "110%" }}
        >
          {resultLabel}
        </p>
        <p className="flex items-center">
          <span style={{ fontSize: "110%" }} className="mr-1">
            5.0
          </span>
          <AiFillStar
            style={{
              color: "gold", // Golden yellow color
              fontSize: "110%",
              textShadow: "0 0 5px rgba(255, 255, 255, 0.8)", // White text shadow for a 3D effect
            }}
          />
          <span
            style={{ fontSize: "110%", height: " 37.3%" }}
            className="ml-1 text-[0.9rem]"
          >
            (1)
          </span>
        </p>

        <p className="text-[0.9rem]">Hotel</p>

        <div className="">
          <Button
            type="primary"
            style={{ fontSize: "100%", backgroundColor: "#1211FF" }}
            icon={<BiSolidDirectionRight />}
            onClick={(e) => updateDirectionClicked(true)}
          >
            Direction
          </Button>
        </div>
      </div>

      {/* for Desktop */}
      <div
        className={`${
          collapsed ? "w-16" : "w-72"
        } bg-white text-black transition-all duration-300 ease-in-out overflow-hidden mobile:hidden steps-list  overflow-y-auto`}
        style={{ height: "100dvh" }}
      >
        <div className="flex justify-end">
          <p
            className="items-center pr-4 pt-2 "
            onClick={() => setShowContributions(false)}
          >
            <IoClose className="text-xl" />
          </p>
        </div>

        <h1 className="h-9vh flex items-center justify-center text-blue-900 mt-8 mb-4 font-bold text-lg">
          Your Contributions
        </h1>
        {myPlace &&
          myPlace.map(
            (place, index) =>
              place.properties && (
                <div className="px-2 pl-4" key={index}>
                  <div className="flex">
                    <h1 className="font-bold text-sm mt-4 text-gray-600">
                      {place.properties.addendum.osm.operator}
                    </h1>
                    <span className="ml-3 mt-5" onClick={() => handleEdit()}>
                      <CiEdit />
                    </span>
                    <Modal
                      title="Edit Place"
                      open={isModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                    >
                      <Form
                        form={form}
                        onFinish={(values) => onFinish(values, place)}
                        layout="vertical"
                        initialValues={{
                          placeName: `${place.properties.addendum.osm.operator}`,
                          coordinates: `${place.coordinates}`,
                        }}
                      >
                        <Form.Item
                          label="Place Name"
                          name="placeName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter a place name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Coordinates"
                          name="coordinates"
                          rules={[
                            {
                              required: true,
                              message: "Please select coordinates",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                        <MapComponent
                          onMapClick={handleMapClick}
                          markerPosition={markerPosition}
                        />

                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Add Place
                          </Button>
                        </Form.Item>
                      </Form>
                    </Modal>
                  </div>
                  <p className="text-gray-400 text-xs">
                    {place.coordinates[0]} , {place.coordinates[1]}{" "}
                  </p>
                  <p className="text-gray-400 mt-4 text-sm">Created at:</p>
                  <p className="text-gray-400 text-sm">
                    {formatDate(place.createdAt)}
                  </p>
                  <hr className="mt-4" />
                </div>
              )
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    collapsed: state.app.collapsed,
    myPlace: state.place.myPlace,
    ...state.directions,
    ...state.search,
  };
};

const mapDispatchToProps = {
  toggleCollapsed,
  updateDirectionClicked,
  getPlace,
  setShowContributions,
  editPlace,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
