import { put, takeEvery } from 'redux-saga/effects';
import { setIsMobile } from './appState';

function* handleResize() {
  yield window.addEventListener('resize', function() {
    put(setIsMobile(window.innerWidth < 639));
  });
}

export default function* rootSaga(){
    yield takeEvery('app/handleResize', handleResize);
}