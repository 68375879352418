import { createSlice } from "@reduxjs/toolkit";

export const navSlice = createSlice({
    name: 'navigation',
    initialState:{
        routeCoordinates:[],
        markerCoordinates:[],
        labels:[],
        selectedRoute: 0,
        directionClicked: false,//sessionStorage.getItem('directionClicked')==='true'
        loadRoutes: false,
        showRoutesMob:{
            polylineDrawn: false,//sessionStorage.getItem('polylineDrawn')==='true'
            clickedRouteDistance:null,
            clickedRouteDuration:null,
            clickedRouteSteps:[]
        }
        
    },
    reducers:{
        updateRoute: (state, action) => {
            state.routeCoordinates = action.payload
        },
        updateMarkerCoordinate: (state, action) => {
            state.markerCoordinates = action.payload
        },
        updateLabels: (state, action) => {
            state.labels = action.payload
        },
        updateSelectedRoute: (state, action) => {
            state.selectedRoute = action.payload
        },
        updateDirectionClicked: (state, action) => {
            state.directionClicked = action.payload
        },
        updateShowRoutesMob: (state, action) => {
            state.showRoutesMob.polylineDrawn = action.payload;
        },
        updateLoading : (state, action) => {
            state.loadRoutes = action.payload
        },
    }
});

export const {updateRoute,updateMarkerCoordinate,updateLabels,updateSelectedRoute,updateDirectionClicked,updateShowRoutesMob,updateLoading} = navSlice.actions;
export default navSlice.reducer;
