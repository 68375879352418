import React, { useState, useEffect, useRef } from "react";
import { Button, Input } from "antd";
import { FaMapMarkerAlt, FaWalking, FaTimes, FaBicycle } from "react-icons/fa";
import { BsFillCarFrontFill } from "react-icons/bs";
import { MdArrowBackIos } from "react-icons/md";
import axios from "axios";
import { connect } from "react-redux";
import {
  updateRoute,
  updateMarkerCoordinate,
  updateLabels,
  updateDirectionClicked,
  updateSelectedRoute,
  updateShowRoutesMob,
  updateLoading,
} from "../../redux/navigation/navState";
import { update } from "../../redux/directions/directionState";
import { FaArrowsAltV } from "react-icons/fa";
import {
  updateSteps,
  updateRoutes,
} from "../../redux/directions/directionState";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./navigation.css";
import { LoadingOutlined } from "@ant-design/icons";

const AppNavigation = ({
  dispatchUpdate,
  updateMarker,
  updateRoutes,
  updateSelectedRoute,
  directionClicked,
  updateLoading,
}) => {
  const navigate = useNavigate();

  const [startingPoint, setStartingPoint] = useState("");
  const [startingPointResults, setStartingPointResults] = useState([]);

  const [destinationPoint, setDestinationPoint] = useState("");
  const [destinationResults, setDestinationResults] = useState([]);

  const [activeInput, setActiveInput] = useState(null);

  const [middleDestinations, setMiddleDestinations] = useState([]);
  const [middleDestinationResults, setMiddleDestinationResults] = useState([]);

  const containerRef = useRef(null);
  const [startRouteCoordinates, setStartRouteCoordinates] = useState([]);
  const [destinationRouteCoordinates, setDestinationRouteCoordinates] =
    useState([]);
  const [middleRouteCoordinates, setMiddleRouteCoordinates] = useState([]);
  const [showSwapButton, setShowSwapButton] = useState(true);
  const [activeButton, setActiveButton] = useState("driving-car");

  const [loadingStart, setLoadingStart] = useState(false);
  const [loadingDest, setLoadingDest] = useState(false);
  const [loadingMiddle, setLoadingMiddle] = useState([]);

  const location = useLocation();
  const startCoordinates = useRef([]);
  const destinationCoordinates = useRef([]);
  const middleCoordinates = useRef([]);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setStartingPointResults([]);
        setDestinationResults([]);
        setMiddleDestinationResults([]);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (directionClicked) {
      const label = urlParams.get("query");
      const latitude = parseFloat(urlParams.get("latitude"));
      const longitude = parseFloat(urlParams.get("longitude"));

      if (label && latitude && longitude) {
        const bota = "destination";
        setDestinationRouteCoordinates([
          { coordinates: [longitude, latitude], label, bota },
        ]);
        setDestinationPoint(label);
      }
    }
    const combinedCoordinateLabels2 = JSON.parse(
      urlParams.get("combinedCoordinateLabels")
    );
    if (
      startRouteCoordinates.length === 0 &&
      destinationRouteCoordinates.length === 0 &&
      middleRouteCoordinates.length === 0 &&
      !startingPoint &&
      !destinationPoint
    ) {
      if (combinedCoordinateLabels2) {
        const [startCoords, ...middleAndDestinationCoords] =
          combinedCoordinateLabels2;
        const destinationCoords = middleAndDestinationCoords.pop();
        if (middleAndDestinationCoords != 0) {
          setShowSwapButton(false);
        }
        coordinates(
          [startCoords],
          destinationCoords,
          middleAndDestinationCoords
        );
        setStartRouteCoordinates([startCoords]);
        setMiddleRouteCoordinates(middleAndDestinationCoords);
        setDestinationRouteCoordinates([destinationCoords]);

        setDestinationPoint(destinationCoords.label);
        setStartingPoint(startCoords.label);

        const combinedRouteCoordinates = [
          ...startCoordinates.current,
          ...middleCoordinates.current,
          ...destinationCoordinates.current,
        ];
        const coordinatesString = combinedRouteCoordinates
          .map((coord) => coord.join(","))
          .join(";");

        getDirections(
          combinedCoordinateLabels2,
          coordinatesString,
          activeButton
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      (startRouteCoordinates.length > 0 &&
        destinationRouteCoordinates.length > 0) ||
      (destinationRouteCoordinates.length > 0 &&
        middleRouteCoordinates.length > 0) ||
      (startRouteCoordinates.length > 0 && middleRouteCoordinates.length > 0)
    ) {
      startCoordinates.current = startRouteCoordinates.map(
        (route) => route.coordinates
      );
      destinationCoordinates.current = destinationRouteCoordinates.map(
        (route) => route.coordinates
      );
      middleCoordinates.current = middleRouteCoordinates.map(
        (route) => route.coordinates
      );

      const combinedRouteCoordinates = [
        ...startCoordinates.current,
        ...middleCoordinates.current,
        ...destinationCoordinates.current,
      ];

      const combinedCoordinateLabels = [
        ...startRouteCoordinates,
        ...middleRouteCoordinates,
        ...destinationRouteCoordinates,
      ];

      const coordinatesString = combinedRouteCoordinates
        .map((coord) => coord.join(","))
        .join(";");
      updateLoading(true);
      getDirections(combinedCoordinateLabels, coordinatesString, activeButton);
    }
  }, [
    startRouteCoordinates,
    destinationRouteCoordinates,
    middleRouteCoordinates,
    activeButton,
  ]);

  const handleMiddleDestinationChange = async (index, value) => {
    setLoadingMiddle((prevLoadingMiddle) => {
      const updatedLoadingMiddle = [...prevLoadingMiddle];
      updatedLoadingMiddle[index] = true;
      return updatedLoadingMiddle;
    });
    try {
      setMiddleDestinations((prevMiddleDestinations) => {
        const updatedMiddleDestinations = [...prevMiddleDestinations];
        updatedMiddleDestinations[index] = value;
        return updatedMiddleDestinations;
      });
      const response = await axios.get(
        `${process.env.REACT_APP_SEARCH_URL}&text=${value}&boundary.country=ETH`
      );
      // Create a new array to store the autocomplete results for each middle destination
      const updatedAutocompletes = [...middleDestinationResults];
      updatedAutocompletes[index] = response.data.features.slice(0, 5);
      setMiddleDestinationResults(updatedAutocompletes);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingMiddle((prevLoadingMiddle) => {
        const updatedLoadingMiddle = [...prevLoadingMiddle];
        updatedLoadingMiddle[index] = false;
        return updatedLoadingMiddle;
      }); // Set loading to false when the search results are received.
    }
  };

  const handleStartingPointInputChange = async (e) => {
    const value = e.target.value;
    setLoadingStart(true);
    try {
      setStartingPoint(value);
      setActiveInput("start");
      const response = await axios.get(
        `${process.env.REACT_APP_SEARCH_URL}&text=${value}&boundary.country=ETH`
      );
      setStartingPointResults(response.data.features.slice(0, 5));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingStart(false); // Set loading to false when the search results are received.
    }
  };

  const handleDestinationInputChange = async (e) => {
    const value = e.target.value;
    setLoadingDest(true);
    try {
      setDestinationPoint(value);
      setActiveInput("destination");
      const response = await axios.get(
        `${process.env.REACT_APP_SEARCH_URL}&text=${value}&boundary.country=ETH`
      );
      setDestinationResults(response.data.features.slice(0, 5));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDest(false); // Set loading to false when the search results are received.
    }
  };

  const coordinates = (
    startCoords,
    destinationCoords,
    middleAndDestinationCoords
  ) => {
    startCoordinates.current = startCoords.map((route) => route.coordinates);
    destinationCoordinates.current = [destinationCoords].map(
      (route) => route.coordinates
    );

    if (middleAndDestinationCoords.length > 0) {
      middleCoordinates.current = middleAndDestinationCoords.map(
        (route) => route.coordinates
      );
      middleAndDestinationCoords.forEach((result) => {
        setMiddleDestinations((prevMiddleDestinations) => {
          const updatedMiddleDestinations = [...prevMiddleDestinations];
          updatedMiddleDestinations[result.bota] = result.label;
          return updatedMiddleDestinations;
        });
      });
    }
  };

  const updateCoordinates = async (updatedCoordinates) => {
    const updatedSearch = new URLSearchParams();
    updatedSearch.set("activeButton", activeButton);
    updatedSearch.set(
      "combinedCoordinateLabels",
      JSON.stringify(updatedCoordinates)
    );
    navigate(`/?${updatedSearch.toString()}`);
  };

  const getDirections = async (
    combinedCoordinateLabels,
    coordinatesString,
    activeButton
  ) => {
    try {
      const osrmBaseUrl = `${process.env.REACT_APP_ROUTING_URL}`;
      // const url = `${osrmBaseUrl}/${activeButton}/${coordinatesString}?alternatives=true&steps=true&geometries=geojson`;
      const url = `${osrmBaseUrl}&text=${coordinatesString}`;
      const response = await axios.get(url);

      if (response.data.code === "Ok") {
        const routes = response.data.routes.map((route) => ({
          directions: route.geometry.coordinates,
        }));
        updateCoordinates(combinedCoordinateLabels);
        updateSelectedRoute(0);
        dispatchUpdate(routes);
        updateRoutes(response.data.routes);
        updateMarker(combinedCoordinateLabels);
        updateLoading(false);
        // updateShowRoutesMob(true)
      } else {
        console.error("OSRM Error:", response.data);
      }
    } catch (error) {
      console.error("Error fetching directions:", error);
    }
  };

  const placeMarker = async (longitude, latitude, bota, label) => {
    if (bota === "start") {
      setStartRouteCoordinates([
        { coordinates: [longitude, latitude], label, bota },
      ]);
    } else if (bota === "destination") {
      setDestinationRouteCoordinates([
        { coordinates: [longitude, latitude], label, bota },
      ]);
    } else {
      setMiddleRouteCoordinates((prevRouteCoordinates) => {
        const updatedCoordinates = [...prevRouteCoordinates];
        updatedCoordinates[bota] = {
          coordinates: [longitude, latitude],
          label,
          bota,
        };
        return updatedCoordinates;
      });
    }
  };

  const handleListElementClick = async (
    label,
    bota,
    latitude,
    longitude,
    e
  ) => {
    setStartingPointResults([]);
    setDestinationResults([]);
    setMiddleDestinationResults([]);
    placeMarker(longitude, latitude, bota, label);

    if (bota === "start") {
      setStartingPoint(label);
    } else if (bota === "destination") {
      setDestinationPoint(label);
    } else {
      setMiddleDestinations((prevMiddleDestinations) => {
        const updatedMiddleDestinations = [...prevMiddleDestinations];
        updatedMiddleDestinations[bota] = label;
        return updatedMiddleDestinations;
      });
    }
  };

  const handleAddRouteClick = async () => {
    setMiddleDestinations((prevMiddleDestinations) => [
      ...prevMiddleDestinations,
      "",
    ]);
    setShowSwapButton(false);
  };

  const handleDeleteMiddleDestination = (index) => {
    setMiddleDestinations((prevMiddleDestinations) => {
      const updatedMiddleDestinations = [...prevMiddleDestinations];
      updatedMiddleDestinations.splice(index, 1);
      if (updatedMiddleDestinations.length === 0) {
        setShowSwapButton(true);
      }
      return updatedMiddleDestinations;
    });

    setMiddleRouteCoordinates((prevMiddleRouteCoordinates) => {
      const updatedMiddleRouteCoordinates = [...prevMiddleRouteCoordinates];
      updatedMiddleRouteCoordinates.splice(index, 1);
      return updatedMiddleRouteCoordinates;
    });
  };

  const handleToggleInput = async () => {
    if (startingPoint && destinationPoint) {
      // Swap the values only if both starting and destination points are selected
      setDestinationPoint(startingPoint);
      setStartingPoint(destinationPoint);
      setStartRouteCoordinates(destinationRouteCoordinates);
      setDestinationRouteCoordinates(startRouteCoordinates);
      setStartingPointResults([]);
      setDestinationResults([]);

      setMiddleDestinationResults([]);
    }
  };

  const handleDragStart = (e, type) => {
    e.dataTransfer.setData("text/plain", type);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    const sourceType = e.dataTransfer.getData("text");

    if (type !== sourceType) {
      if (type === "start") {
        const temp = destinationPoint;
        setDestinationPoint(startingPoint);
        setStartingPoint(temp);
        setStartRouteCoordinates(destinationRouteCoordinates);
        setDestinationRouteCoordinates(startRouteCoordinates);
      } else if (type === "destination") {
        const temp = startingPoint;
        setStartingPoint(destinationPoint);
        setDestinationPoint(temp);
        setStartRouteCoordinates(destinationRouteCoordinates);
        setDestinationRouteCoordinates(startRouteCoordinates);
      }
    }
  };

  return (
    <div ref={containerRef} className=" w-56 mx-auto items-center">
      <h1 className="font-semibold mb-2 text-15 ml-15 mb-0 mobile:hidden">
        Navigation
      </h1>
      <h1
        className="font-semibold mb-2 text-15 ml-15 mb-2 mt-5 hidden mobile:block"
        style={{ marginLeft: "-20%", fontSize: "19px" }}
        onClick={(e) => {
          navigate(`/`);
          window.location.reload(true);
        }}
      >
        <MdArrowBackIos />
      </h1>

      <div
        className="flex relative mb-1 mt-1 items-center cursor-grab"
        draggable
        onDragStart={(e) => handleDragStart(e, "start")}
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e, "start")}
      >
        <div className="relative">
          <FaMapMarkerAlt className="mr-2 text-black" />
          <div className="border-l border-dashed border-gray-500 h-6 ml-2 -mt-0 absolute z-10"></div>
        </div>

        <Input
          placeholder="Starting Point"
          value={startingPoint}
          onChange={handleStartingPointInputChange}
        />
        {loadingStart && (
          <div className="absolute  top-0 z-10 right-2">
            <LoadingOutlined />
          </div>
        )}
      </div>

      {startingPointResults.length > 0 && activeInput === "start" && (
        <ul className="rounded-md shadow-md bg-white pb-1 w-54 mx-auto px-3 text-sm md:shadow-xl">
          {startingPointResults.map((result) => (
            <li
              key={result.properties.id}
              className="hover:bg-gray-200 bg-gray-100 rounded-md p-1 mb-1"
            >
              <button
                onClick={(e) =>
                  handleListElementClick(
                    result.properties.label,
                    "start",
                    result.geometry.coordinates[1],
                    result.geometry.coordinates[0],
                    e
                  )
                }
                className="block w-full p-1 text-left"
              >
                {result.properties.label}
              </button>
            </li>
          ))}
        </ul>
      )}

      {showSwapButton && (
        <Button
          size="small"
          icon={<FaArrowsAltV />}
          onClick={handleToggleInput}
          className="bg-transparent border-none ml-28 shadow-none py-0 h-0 "
        />
      )}
      {/* </div> */}

      {middleDestinations.map((destination, index) => (
        <React.Fragment key={index}>
          <div className="flex relative mb-2 mt-2 items-center cursor-grab ">
            <div className="relative">
              <FaMapMarkerAlt className="mr-2 text-black" />
              <div className="border-l border-dashed border-gray-500 h-6 ml-2 -mt-0 absolute z-10"></div>
            </div>
            <Input
              placeholder={`Middle Destination ${index + 1}`}
              value={destination}
              onChange={(e) =>
                handleMiddleDestinationChange(index, e.target.value)
              }
              className="w-full"
            />
            {loadingMiddle[index] && (
              <div className="absolute  top-0 z-10 right-7">
                <LoadingOutlined />
              </div>
            )}
            <FaTimes
              onClick={() => handleDeleteMiddleDestination(index)}
              className="cursor-pointer ml-2"
            />
          </div>

          {middleDestinationResults[index] &&
            middleDestinationResults[index].length > 0 && (
              <ul className="rounded-md shadow-md bg-white pb-1 w-54 mx-auto px-3 text-sm md:shadow-xl mb-2">
                {middleDestinationResults[index].map((result) => (
                  <li
                    key={result.properties.id}
                    className="hover:bg-gray-200 bg-gray-100 rounded-md p-1 mb-1"
                  >
                    <button
                      onClick={(e) =>
                        handleListElementClick(
                          result.properties.label,
                          `${index}`,
                          result.geometry.coordinates[1],
                          result.geometry.coordinates[0],
                          e
                        )
                      }
                      className="block w-full p-1 text-left"
                    >
                      {result.properties.label}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          {/* <Divider type="vertical" style={{ height: "20px", borderColor: "#000", marginLeft: '37px' }} dashed /> */}
        </React.Fragment>
      ))}
      <div
        className="flex relative mb-3 items-center cursor-grab"
        draggable
        onDragStart={(e) => handleDragStart(e, "destination")}
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e, "destination")}
      >
        <div className="relative">
          <div className="border-l border-dashed border-gray-500 h-6 ml-2 -mt-6 absolute z-10"></div>
          <FaMapMarkerAlt className="mr-2 text-red-500 relative z-20" />
        </div>

        <Input
          placeholder="Destination Point"
          value={destinationPoint}
          onChange={handleDestinationInputChange}
          className="w-full"
        />
        {loadingDest && (
          <div className="absolute  top-0 z-10 right-2">
            <LoadingOutlined />
          </div>
        )}
      </div>

      {destinationResults.length > 0 && activeInput === "destination" ? (
        <ul className="rounded-md shadow-md bg-white pb-1 w-54 mx-auto px-3 text-sm md:shadow-xl mb-2">
          {destinationResults.map((result) => (
            <li
              key={result.properties.id}
              className="hover:bg-gray-200 bg-gray-100 rounded-md p-1 mb-1"
            >
              <button
                onClick={(e) =>
                  handleListElementClick(
                    result.properties.label,
                    "destination",
                    result.geometry.coordinates[1],
                    result.geometry.coordinates[0],
                    e
                  )
                }
                className="block w-full p-1 text-left"
              >
                {result.properties.label}
              </button>
            </li>
          ))}
        </ul>
      ) : null}

      <Button
        onClick={handleAddRouteClick}
        className="mb-3"
        style={{
          width: "100%",
          // marginLeft: '30%',
          background: "#F5F5F5",
          borderColor: "#F5F5F5",
        }}
      >
        Add Route
      </Button>

      <div className="flex mx-auto">
        <Button
          style={{
            fontSize: " 12px",
            height: "23px",
            padding: "3px 23px",
            borderRadius: "8px",
          }}
          className={`custom-button ${
            activeButton === "driving-car" ? "clicked" : ""
          }`}
          onClick={() => handleButtonClick("driving-car")}
        >
          <BsFillCarFrontFill
            style={{
              color: activeButton === "driving-car" ? "white" : "black",
              fontSize: "150%",
            }}
          />
        </Button>
        <Button
          style={{
            fontSize: " 12px",
            height: "23px",
            padding: " 3px 23px",
            borderRadius: "8px",
          }}
          className={`custom-button ${
            activeButton === "foot-walking" ? "clicked" : ""
          }`}
          onClick={() => handleButtonClick("foot-walking")}
        >
          <FaWalking
            style={{
              color: activeButton === "foot-walking" ? "white" : "black",
              fontSize: "130%",
            }}
          />
        </Button>
        <Button
          style={{
            fontSize: " 12px",
            height: "23px",
            padding: " 3px 23px",
            borderRadius: "8px",
          }}
          className={`custom-button ${
            activeButton === "cycling-regular" ? "clicked" : ""
          }`}
          onClick={() => handleButtonClick("cycling-regular")}
        >
          <FaBicycle
            style={{
              color: activeButton === "cycling-regular" ? "white" : "black",
              fontSize: "150%",
            }}
          />
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ...state.navigation,
  };
}

const mapDispatchToProps = {
  dispatchUpdate: updateRoute, // Rename the prop here to avoid conflict
  updateMarker: updateMarkerCoordinate,
  updateLabels: updateLabels,
  updateSteps: updateSteps,
  updateShowSteps: update,
  updateRoutes: updateRoutes,
  updateDirectionClicked: updateDirectionClicked,
  updateSelectedRoute: updateSelectedRoute,
  updateShowRoutesMob: updateShowRoutesMob,
  updateLoading: updateLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation);
