import { combineReducers } from "redux";
import appReducer from "./app/appState";
import authReducer from "./auth/authState";
import searchReducer from "./Search/searchState";
import navigationReducer from "./navigation/navState";
import directionsReducer from "./directions/directionState";
import reverseSlice from "./reverse/reverseState";
import resetSlice from "./reset/resetState";
import placeSlice from "./places/placeState";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  search: searchReducer,
  navigation: navigationReducer,
  directions: directionsReducer,
  reverseSlice: reverseSlice,
  resetSlice: resetSlice,
  place: placeSlice,
  // Add other reducers here
});

export default rootReducer;
