import { createSlice } from "@reduxjs/toolkit";

export const placeSlice = createSlice({
  name: "place",
  initialState: {
    placeData: [],
    myPlace: [],
    showContributions: false,
  },
  reducers: {
    updatePlaceData: (state, action) => {
      state.myPlace = action.payload;
    },
    addPlace: (state, action) => {
      // Assuming the payload is an array of places
    },
    editPlace: (state, action) => {
      // Assuming the payload is an array of places
    },
    getPlace: (state, action) => {
      // Assuming the payload is an array of places
    },
    setShowContributions: (state, action) => {
      state.showContributions = action.payload;
    },
  },
});

export const {
  addPlace,
  updatePlaceData,
  getPlace,
  setShowContributions,
  editPlace,
} = placeSlice.actions;
export default placeSlice.reducer;
