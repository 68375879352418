import { createSlice } from "@reduxjs/toolkit";

export const resetSlice = createSlice({
  name: "reset",
  initialState: {
    emailSent: false,
    restPassword: false,
  },
  reducers: {
    updateEmailSent: (state, action) => {
      state.emailSent = action.payload;
    },
    updateResetPassword: (state, action) => {
      state.restPassword = action.payload;
    },
    forgot: (state, action) => {},
    reset: (state, action) => {},
    // loginSuccess: (state, action) => {
    //   state.idToken = action.payload.token;
    //   state.error = null;
    //   state.signinSuccess = true;
    // },
    // loginFailure: (state, action) => {
    //   state.idToken = null;
    //   state.error = action.payload; // Set the error message in the state
    // },
  },
});

export const { updateEmailSent, updateResetPassword, forgot, reset } =
  resetSlice.actions;
export default resetSlice.reducer;
