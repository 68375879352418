import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Title,
  WelcomeText,
  SignInText,
  HorizontalLine,
} from "../Signin/login.style";
import { Button, Checkbox, Form, Input } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  forgot,
  reset,
  updateResetPassword,
} from "../../../redux/reset/resetState";
import ResetPassword from "./resetPassword";

const ForgotPassword = ({
  updateResetPassword,
  forgot,
  reset,
  restPassword,
}) => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const onFinish = (values) => {
    // updateResetPassword(true);
    forgot(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {restPassword && <ResetPassword />}
      {!restPassword && (
        <div className="flex-grow w-1/2 bg-white-500 mobile:w-full mobile:h-full mb-5">
          <Title style={{ height: "9vh", color: "#131466" }}>
            ETHIO<b>MAPS</b>
          </Title>
          <WelcomeText>Forgot your password?</WelcomeText>
          <SignInText className="sign-in-text">
            We'll send a verification code to your email.
          </SignInText>
          <HorizontalLine />
          <Form
            name="basic"
            layout="vertical"
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="px-16"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
              style={{ marginBottom: 10 }}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                className="w-full text-white"
                style={{ background: "#2a29cc", marginTop: "20px" }}
                htmlType="submit"
              >
                Send verification code
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    ...state.resetSlice,
    // ...state.search,
  };
}
const mapDispatchToProps = {
  forgot,
  reset,
  updateResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
