import { all } from "redux-saga/effects";
import auth from "./auth/saga";
import app from "./app/saga";
import reverse from "./reverse/saga";
import reset from "./reset/saga";
import place from "./places/saga";

export default function* rootSaga() {
  yield all([auth(), app(), reverse(), reset(), place()]);
}
