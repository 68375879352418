// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider, FacebookAuthProvider} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCPCz-__vQ9Fzt7h7M6fss9WgBnaqLxosM",
  authDomain: "yeha-24f6c.firebaseapp.com",
  projectId: "yeha-24f6c",
  storageBucket: "yeha-24f6c.appspot.com",
  messagingSenderId: "1080485753749",
  appId: "1:1080485753749:web:62910358540332f682fc9c",
  measurementId: "G-9FQD8RYQF8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const fbprovider = new FacebookAuthProvider()
// const analytics = getAnalytics(app);