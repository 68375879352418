import React, { useState, useEffect } from 'react';
import { BiSolidDirectionRight } from 'react-icons/bi';
import { CloseOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import {updateShowLocation} from "../../redux/reverse/reverseState"

const LocationCard = ({label,updateShowLocation}) => {
  const handleCancel = () => {
    updateShowLocation(false);
  };

  useEffect(() => {
    console.log(label)
}, [label]);

  const handleClick = () => {

  }

  return  (
    <div className="bg-white rounded-lg overflow-hidden shadow-md mt-5 mobile:mt-12 mobile:m-auto ml-5 w-80 absolute z-40">   
      <div className="flex justify-between items-center px-4 py-3">
        <button className="absolute top-0 right-0 text-gray-800 font-bold px-2" onClick={handleCancel}>
            <CloseOutlined />
        </button>
        <div className="pr-0 mr-0">
          <h2 className="text-sm font-bold mb-1 ">{label.name}</h2>
          <p className="text-gray-700 text-sm mb-1">{label.region}</p>
          <hr className='mb-1 w-2/3'/>
          <p className="text-gray-700 text-sm font-light">{label.coordinates[1]}, {label.coordinates[0]}</p>
        </div>
        <div className="">
          {/* <button onClick={handleClick()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-full flex items-center justify-center p-2 w-9 h-9">
            <BiSolidDirectionRight className="text-3xl" />
          </button> */}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state){
    return {
      ...state.reverseSlice,
    };
  }
  const mapDispatchToProps = {
    updateShowLocation:updateShowLocation,
  };
  
export default connect(mapStateToProps,mapDispatchToProps)(LocationCard);
