import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  signupSuccess,
  getLoggedInUserSuccess,
} from "./authState";
import { notification } from "antd";
import jwt_decode from "jwt-decode";
import { auth, provider, fbprovider } from "../../config/firebase";
import { signInWithPopup } from "firebase/auth";

const openNotificationWithIcon = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};

function* workLogin(action) {
  const { email, password } = action.payload;
  try {
    const userData = {
      email: email,
      password: password,
    };

    const response = yield call(() =>
      axios.post(`${process.env.REACT_APP_AUTH_URL}publicLogin`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    yield put(loginSuccess(response.data));
  } catch (error) {
    // console.log(error)
    openNotificationWithIcon("error", " Incorrect Username or Password");
    yield put(loginFailure("Incorrect Username or Password"));
  }
}

function* workGoogleLogin(action) {
  try {
    const result = yield call(() => signInWithPopup(auth, provider));

    // Get the Gmail ID token from the authentication result
    const idToken = yield call(() => result.user.getIdToken());

    // Send the Gmail ID token to the backend
    const response = yield call(() =>
      axios.post(`${process.env.REACT_APP_AUTH_URL}signUpWithGmail`, {
        idToken,
      })
    );
    const response2 = yield call(() =>
      axios.post(`${process.env.REACT_APP_AUTH_URL}loginWithGmail`, {
        idToken,
      })
    );

    yield put(loginSuccess(response2.data));
  } catch (error) {
    // console.log(error)
    openNotificationWithIcon("error", " Incorrect Username or Password");
  }
}

function* workFacebookLogin(action) {
  try {
    const result = yield call(() => signInWithPopup(auth, fbprovider));

    // Get the Gmail ID token from the authentication result
    const idToken = yield call(() => result.user.getIdToken());

    // Send the Gmail ID token to the backend
    const response = yield call(() =>
      axios.post(`${process.env.REACT_APP_AUTH_URL}signUpWithFacebook`, {
        idToken,
      })
    );
    const response2 = yield call(() =>
      axios.post(`${process.env.REACT_APP_AUTH_URL}loginWithFacebook`, {
        idToken,
      })
    );

    yield put(loginSuccess(response2.data));
  } catch (error) {
    // console.log(error)
    openNotificationWithIcon("error", " Incorrect Username or Password");
  }
}

export function* workLoginSuccess(action) {
  openNotificationWithIcon("success", "Signin successfull");
  yield localStorage.setItem("publicToken", action.payload.token);
}

function performLogout() {
  localStorage.removeItem("publicToken");
  return Promise.resolve();
}

function* workLogout() {
  try {
    yield call(performLogout);
    yield put(logoutSuccess());
  } catch (error) {
    // console.log(error);
  }
}

function* workSignup(action) {
  try {
    const userData = {
      firstName: action.payload.firstname,
      email: action.payload.email,
      lastName: action.payload.lastname,
      phoneNumber: action.payload.phone,
      password: action.payload.password,
    };
    yield call(() =>
      axios.post(`${process.env.REACT_APP_AUTH_URL}signup`, userData)
    );
    //Show success notification for insert
    yield put(signupSuccess());
    openNotificationWithIcon("success", "signup successfull");
  } catch (error) {
    // Display error notification
    openNotificationWithIcon(
      "error",
      error.response ? error.response.data.error : "Error occurred"
    );
    // console.log(error)
  }
}

function* workGetLoggedInUser(action) {
  try {
    const token = action.payload.idToken;
    const response = yield call(() =>
      axios.get(
        `${process.env.REACT_APP_AUTH_URL}user/${jwt_decode(token).id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );
    const user = response.data;
    yield put(getLoggedInUserSuccess(user));
  } catch (error) {
    // console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery("auth/login", workLogin),
    takeEvery("auth/googleLogin", workGoogleLogin),
    takeEvery("auth/facebookLogin", workFacebookLogin),
    takeEvery("auth/signup", workSignup),
    takeEvery("auth/loginSuccess", workLoginSuccess),
    // takeEvery('auth/login', workLogin),
    takeEvery("auth/getLoggedInUser", workGetLoggedInUser),
    takeEvery("auth/logout", workLogout),
    // takeEvery('user/createUser', workCreateUsers)
  ]);
}
