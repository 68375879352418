import React from "react";

const GradientButton = ({ text, onClick }) => {
  return (
    <button
      className={` z-10 bg-gradient-to-br from-[#2A29CC] to-[#2A29CC] hover:from-blue-600 hover:to-blue-600 
        text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out mobile:py-1 mobile: px-2 mobile:text:sm shadow-md`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default GradientButton;
