import { put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { getLabel, updateShowLocation } from "./reverseState.js";

function* handleMapClick(action) {
  try {
    const response = yield call(() =>
      axios.get(
        `${process.env.REACT_APP_REVERSE_URL}&lat=${action.payload.latitude}&lon=${action.payload.longitude}&size=1`
      )
    );
    yield put(
      getLabel({
        name: response.data.features[0].properties.name,
        region: response.data.features[0].properties.region,
        coordinates: response.data.features[0].geometry.coordinates,
      })
    );
    yield put(updateShowLocation(true));
  } catch (error) {
    // yield put(loginFailure("Incorrect Username or Password"));
  }
}

export default function* rootSaga() {
  yield takeEvery("reverse/update", handleMapClick);
}
