import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Input } from "antd";
import "../../index.css";
import { connect } from "react-redux";
import { update, updateResultClicked } from "../../redux/Search/searchState";
import { updateRoute } from "../../redux/navigation/navState";
import "./search.css";
import { BiSolidDirectionRight } from "react-icons/bi";
import { updateDirectionClicked } from "../../redux/navigation/navState";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const { Search } = Input;

const AppSearch = ({
  dispatchUpdate,
  dispatchUpdateRoute,
  updateResultClicked,
  updateDirectionClicked,
  resultClicked,
}) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(true);
  const searchRef = useRef(null);
  const [loading, setLoading] = useState(false);
  // const { query, latitude, longitude } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const query = searchParams.get("query");
  const latitude = searchParams.get("latitude");
  const longitude = searchParams.get("longitude");

  useEffect(() => {
    const fetchSearchResults = async () => {
      setShowResults(true);
      setLoading(true); // Set loading to true when starting the search.
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SEARCH_URL}&text=${searchQuery}&boundary.country=ETH`
        );
        const firstFiveResults = response.data.features.slice(0, 5);
        setSearchResults(firstFiveResults);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Set loading to false when the search results are received.
      }
    };

    if (searchQuery) {
      fetchSearchResults();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (query && latitude && longitude) {
      handleListElementClick(query, latitude, longitude);
    }
  }, [query, latitude, longitude]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearch = (value) => {
    setSearchQuery(value);
    navigate(`/${value}`);
    // console.log(value);
  };

  const placeMarker = async (latitude, longitude) => {
    dispatchUpdate([latitude, longitude]);
    // console.log([latitude, longitude]);
  };

  useEffect(() => {
    const handlePopState = () => {
      window.history.replaceState({}, "", "/");
      window.location.reload();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleListElementClick = (label, latitude, longitude, e) => {
    // const { search } = window.location;
    // e.stopPropagation();
    // console.log(window.history.entries);
    dispatchUpdateRoute({});
    const updatedSearch = new URLSearchParams();
    updatedSearch.set("query", label);
    updatedSearch.set("latitude", latitude);
    updatedSearch.set("longitude", longitude);

    // Use the navigate function to update the URL without a full-page navigation
    navigate(`/?${updatedSearch.toString()}`);

    // Update state or perform other actions
    setSearchText(label);
    updateResultClicked(label);
    placeMarker(latitude, longitude);
    setShowResults(false);
  };

  return (
    <div ref={searchRef} className="mb-4 w-56 mx-auto mobile:w-full mobile:p-4">
      <div className="flex flex-col mobile:mt-8 mobile:w-full">
        <h1 className="font-semibold mb-2 text-15  mb-0 mobile:hidden">
          Search
        </h1>
        <div className="flex relative items-center">
          <Search
            placeholder="Location"
            value={searchText}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setSearchText(e.target.value);
            }}
            onSearch={handleSearch}
            className="w-full h-10 mobile:h-full mobile:shadow-lg"
            size={window.innerWidth < 639 ? "large" : "default"}
          />
          {loading && (
            <div
              className={`absolute z-10 ${
                window.innerWidth < 639 ? "right-28 top-1" : "right-9 top-0"
              }`}
            >
              <LoadingOutlined />
            </div>
          )}
          <button
            onClick={(e) => updateDirectionClicked(true)}
            className={`hidden ml-1 bg-[#2A29CC] text-gray-100 font-bold rounded-lg flex items-center mobile:block ${
              resultClicked ? "mobile:hidden" : ""
            }`}
          >
            <BiSolidDirectionRight className="mx-2 text-4xl" />
          </button>
        </div>
      </div>

      {showResults && searchResults.length > 0 && searchQuery && (
        <ul className="rounded-md shadow-md bg-white pb-1 w-54 mx-auto px-3 text-sm md:shadow-xl">
          {searchResults.map((result) => (
            <li
              key={result.properties.id}
              className="hover:bg-gray-200 bg-gray-100 rounded-md p-1 mb-1"
            >
              <button
                onClick={(e) =>
                  handleListElementClick(
                    result.properties.label,
                    result.geometry.coordinates[1],
                    result.geometry.coordinates[0],
                    e
                  )
                }
                className="block w-full p-1 text-left"
              >
                {result.properties.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ...state.search,
    ...state.navigation,
  };
}

const mapDispatchToProps = {
  dispatchUpdate: update,
  dispatchUpdateRoute: updateRoute,
  updateResultClicked: updateResultClicked,
  updateDirectionClicked: updateDirectionClicked,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSearch);
