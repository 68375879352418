import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LeafletMap from '../Map/map';
import Sidebar from '../Sidebar/Sidebar';
import '../../index.css';
import AppSearch from '../Search/search';
import AppNavigation from '../Navigation/navigation';
import { connect } from 'react-redux';
import TopSwipeableDrawer from '../../components/utility/topSwipeableDrawer';
import MobileView from '../../containers/routes/Search/mobileView';
import UserAvatar from '../../components/utility/userAvatar';
import LocationCard from '../../components/utility/locationCard';
import GradientButton from '../../components/utility/buttons';
import {getLoggedInUser,logout} from '../../redux/auth/authState';
import { LoadingOutlined } from '@ant-design/icons';
import Contribution from '../Contribution/Sidebar'

const App = ({ resultClicked, directionClicked,logout, showRoutesMob, showLocation, showSteps, loadRoutes,isLoggedIn,loggedInUser,idToken,getLoggedInUser,showContributions }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 639);
  const navigate = useNavigate();
  const handleResize = () => {
    setIsMobile(window.innerWidth < 639);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if(isLoggedIn){
      getLoggedInUser({idToken});
    }
  }, []);

  const handleClick = (route) => () => {
    if (route === 'signin') {
      navigate('/signin');
    }
    if (route === 'signup') {
      navigate('/signup');
    }
  };

  const handleLogout = () => {
    logout();
    // Your logout logic here
  };

  if (isMobile) {
    return (
      <div className="flex flex-col h-screen relative">
         {loadRoutes && (
          <div className="fixed z-10 inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <LoadingOutlined style={{ fontSize: '350%', color: 'white' }} />
          </div>
        )}
        <div className="bg-gray-200 relative">
        {showLocation && <LocationCard className="" />}
          <LeafletMap />
          <div className={`absolute top-0 left-0 right-0 z-20 ${directionClicked ? 'bg-white p-2' : ''}`}>
            {directionClicked || showRoutesMob.polylineDrawn ? (
              <TopSwipeableDrawer>
                <AppNavigation />
              </TopSwipeableDrawer>
            ) : !showSteps ? (
            <>
              { isLoggedIn ?
              (<UserAvatar handleLogout={handleLogout} username={`${loggedInUser.email}`} firstName={`${loggedInUser.firstName}`} lastName={`${loggedInUser.lastName}`}  />)
              :(
                <div className="absolute top-1 right-3 flex ">
                  <GradientButton text="Sign In" onClick={handleClick('signin')} />
                <div style={{ width: '10px' }}></div>
                <GradientButton text="Sign Up" onClick={handleClick('signup')} />
                </div>
                )}
              <AppSearch /></>
            ) : null}
          </div>
        </div>
        <div className={`bg-white text-black z-50 ${resultClicked && !directionClicked || showRoutesMob.polylineDrawn ? '' : 'mobile:hidden'}`}>
          {showRoutesMob.polylineDrawn ? (showRoutesMob.polylineDrawn ? <MobileView /> : <Sidebar />) : <Sidebar />}
        </div>
      </div>
    );
  }

  return (
    <div className="flex">
        <div className="w-72 bg-white text-black" style={{ height: '100dvh' }}>
          <Sidebar />
        </div>

        {showContributions && (
        <div className="w-72 bg-white text-black" style={{ height: '100dvh' }}>
          <Contribution />
        </div>
        )}

      <div className="flex-1 bg-gray-200 relative">
       
          { isLoggedIn ?(<UserAvatar handleLogout={handleLogout} username={`${loggedInUser.email}`} firstName={`${loggedInUser.firstName}`} lastName={`${loggedInUser.lastName}`}/>)
          :(
           <div className="absolute top-5 right-3 flex">
            <GradientButton text="Sign In" onClick={handleClick('signin')} />
          <div style={{ width: '10px' }}></div>
          <GradientButton text="Sign Up" onClick={handleClick('signup')} />
          </div>
          )}

          {showLocation && <LocationCard className="" />}
        <LeafletMap />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.search,
    ...state.navigation,
    ...state.directions,
    ...state.reverseSlice,
    ...state.place,
    isLoggedIn: state.auth.idToken !== null ? true : false,
    loggedInUser: state.auth.loggedInUser,
    idToken: state.auth.idToken,
  };
};

const mapDispatchToProps = {
  logout:logout,
    getLoggedInUser: getLoggedInUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
