import { createSlice } from "@reduxjs/toolkit";

export const reverseSlice = createSlice({
    name: 'reverse',
    initialState:{
        label:{},
        showLocation:false,
    },
    reducers:{
        update: (state, action) => {
            // Allow callback after state update
            if (action.payload.callback && typeof action.payload.callback === 'function') {
              action.payload.callback(state);
            }},
        getLabel: (state, action) => {
            state.label = action.payload
        },
        updateShowLocation: (state, action) => {
            state.showLocation = action.payload
            if(!action.payload){
                state.label={}
            }
        },
    }
});

export const {update,getLabel,updateShowLocation} = reverseSlice.actions;
export default reverseSlice.reducer;
