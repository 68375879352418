import React from "react";
import "../../index.css";
// import { Link } from 'react-router-dom';

const Logo = ({ collapsed }) => {
  return (
    <div className="flex items-center mt-12 mb-2 justify-center">
      {collapsed ? (
        <div>
          <h3>
            {/* <Link to="/dashboard">
            <i className={siteConfig.siteIcon} />
          </Link> */}
          </h3>
        </div>
      ) : (
        <h3 style={{ height: "9vh", color: "#131466" }}>
          <a to="#" style={{ lineHeight: "0px" }} className="text-3xl">
            ETHIO<strong>MAPS</strong>
          </a>
        </h3>
      )}
    </div>
  );
};

export default Logo;
